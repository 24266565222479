@import "~antd/dist/antd.variable.min.css";

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .dropdown-select:focus,
  .dropdown-select .select__control input:focus {
    font-size: 1.8vh;
  }
}

.App {
  text-align: center;
}
input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus {
  font-size: 16px;
}

/* disable auto-zoom on iphone input field focus */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes AppTest-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-input:focus,
.ant-input-focused {
  border-color: #1d39c4;
}

/*
.ant-input, .ant-select-selector, .ant-select-selection-search-input  {
  border-radius: 14px;
  //height: 56px;
}
*/

/*
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  //height: 59px;
}
*/

.basic-par {
  margin: 0;
}

.citizen-form-footer ul {
  list-style-type: none !important;
}

.account-login-placeholder input::placeholder {
  font-size: 14px;
}

.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.54) !important;
}
.ant-select-selection-placeholder {
  color: #7a7579 !important;
  background-color: #fff;
}
